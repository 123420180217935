/* Slide-in animation */
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Custom Tailwind CSS for animations */
  .animate-slide-in {
    animation: slideIn 0.5s ease-out forwards;
  }
  
  /* Bounce animation for the icon */
  .animate-bounce {
    animation: bounce 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  