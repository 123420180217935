/* Loading Spinner Animation */
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Dots Animation */
  @keyframes blink {
    0%, 20% {
      color: white;
    }
    50% {
      color: transparent;
    }
    100% {
      color: white;
    }
  }
  
  /* Tailwind Custom Animations */
  .loader-circle {
    border-color: rgba(255, 255, 255, 0.4);
    border-top-color: rgba(255, 255, 255, 1);
  }
  
  /* Animated loading dots */
  .loading-dots::after {
    content: '...';
    font-size: 1.2em;
    letter-spacing: 2px;
    animation: blink 1.2s infinite steps(4);
  }
  